<template>
  <b-card>
    <div class="d-flex align-items-center justify-content-between table-header">
      <div class="d-flex align-items-center">
        <label class="inline-label">Show</label>
        <b-form-select v-model="perPage" :options="perPageOptions" @change="handlePerPage" />
      </div>

      <div class="d-flex">
        <b-button variant="danger" @click="resetSearch()" class="ml-1">
          <feather-icon icon="RefreshCcwIcon" />
        </b-button>

        <search :add-button="tableData.length !== 1 ? true : false"
          @addButtonAction="$router.push({ name: 'limithour/form' })" @handleAdvanceSearch="handleSearch"
          @resetAdvanceSearch="handleReset" />
      </div>
    </div>

    <b-table class="border mt-1" :fields="tableField" :items="tableData" show-empty responsive hover :busy="loading">
      <template #table-busy>
        <div class="text-center text-danger my-2" style="padding:50px 0">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(action)="data">
        <b-button-group>
          <b-button variant="flat-dark" @click.prevent="openModalEdit(data.item.kodeparameter, data.item.limit_hour)"
            v-b-tooltip.hover title="Edit">
            <feather-icon size="20" icon="EditIcon" />
          </b-button>

          <b-button variant="flat-dark" @click.prevent="handleRemove(data.item)" v-b-tooltip.hover title="Delete">
            <feather-icon size="20" icon="TrashIcon" />
          </b-button>
        </b-button-group>
      </template>

      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(limit_hour)="data">
        <span class="text-nowrap">{{ data.item.limit_hour }}
          {{
          data.item.limit_hour === 0 ? 'Malam' :
            data.item.limit_hour >= 1 && data.item.limit_hour <= 3 ? 'Dini hari' : data.item.limit_hour >= 4 &&
              data.item.limit_hour <= 10 ? 'Pagi' : data.item.limit_hour >= 11 && data.item.limit_hour <= 14 ? 'Siang' :
              data.item.limit_hour >= 15 && data.item.limit_hour <= 17 ? 'Sore' : data.item.limit_hour === 18 ? 'Petang'
                : data.item.limit_hour >= 19 && data.item.limit_hour <= 24 ? 'Malam' : '' }} </span>
      </template>

      <template #empty="scope">
        <div class="text-center my-75">
          <b-img :src="ClipboardsSecondary" />
          <div class="text-secondary font-small-3 mt-75">
            Tidak ada data
          </div>
        </div>
      </template>
    </b-table>

    <b-modal id="modal-edit" title="Edit Limit Hour" :no-close-on-backdrop="true" hide-footer v-model="isShowModalEdit">
      <form ref="form" @submit.stop.prevent="handleSubmitEdit">
        <b-form-group label="Limit Hour" label-for="limit_hour" invalid-feedback="Limit hour is required">
          <cleave id="limit_hour" v-model="limit_hour" class="form-control" :raw="false"
              :options="options.number" placeholder="Masukkan limit hour anda" />
        </b-form-group>
      </form>

      <div class="d-flex justify-content-end mt-2">
        <b-button class="mr-1" variant="dark" @click="handleTutup">Cancel</b-button>
        <b-button variant="success" @click="handleEdit(selectedData)">Update</b-button>
      </div>
    </b-modal>

    <div class="d-flex justify-content-between flex-wrap">
      <label class="inline-label">Count : {{ tableData.length }} {{ tableData.length > 1 ? 'Items' : 'Item' }}</label>
      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" />
    </div>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BFormSelect } from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import { convertToIDR } from '@/@core/utils/utils'
import ClipboardsSecondary from '@/assets/images/svg/clipboards-secondary.svg'
import Search from './Search.vue'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
    Cleave,
    BFormSelect,
    Search,
  },
  data() {
    return {
      ClipboardsSecondary,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      searchVal: '',
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      tableField: [
        { key: 'no', label: 'No', class: 'text-center', thClass: 'text-center', thStyle: { width: "5%" } },
        { key: 'limit_hour', label: 'Limit Hour', thClass: 'text-center' },
        { key: 'action', label: 'Action', class: 'text-center', thClass: 'text-center', thStyle: { width: "5%" } },
      ],
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        }
      },
      tableData: [],
      loading: false,
      limit_hour: '',
      isShowModalEdit: false,
      selectedData: null,
      selectedNamaData: null,
    }
  },
  watch: {
    currentPage(x, y) {
      if (x !== y) {
        this.getListData()
      }
    },
  },

  mounted() {
    this.getListData()
  },
  methods: {
    convertToIDR,
    isLetter() {
      let char = String.fromCharCode(e.keyCode);
      if (/.*[0-9]/.test(char)) return true;
      else e.preventDefault();
    },

    handleSearch(val) {
      this.searchVal = val
      this.getListData()
    },

    handleReset(val) {
      this.searchVal = val
      this.getListData();
    },

    handlePerPage(val) {
      this.perPage = val
      this.getListData()
    },

    getListData() {
      this.loading = true;
      const params = {
        keyword: this.searchVal.Keyword ?? '',
        page: this.currentPage,
        perPage: this.perPage
      };

      this.$http.get('/api/parameter/list', { params: params }).then(res => {
        this.loading = false;
        this.tableData = res.data.data;
        this.rows = res.data.maxPage * this.perPage;

        if (this.searchVal != '') {
          if (res.data.data.length > 0) {
            this.$toast({
              component: ToastificationContent, position: 'top-right',
              props: {
                title: 'Notification',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Pencarian data berhasil ditemukan',
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent, position: 'top-right',
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                variant: 'warning',
                text: 'Pencarian data tidak ditemukan',
              },
            });
          }
        }
      }).catch(e => {
        this.loading = false;
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal mendapatkan data' + e,
          },
        });
        this.tableData = [];
        this.rows = 0;
      });
    },

    resetSearch() {
      this.searchVal = ''
      this.getListData()
    },

    openModalEdit(kodeparameter, limit_hour) {
      this.selectedData = kodeparameter
      this.selectedNamaData = limit_hour
      this.limit_hour = limit_hour
      this.isShowModalEdit = true;
    },

    handleTutup() {
      this.isShowModalEdit = false;
    },

    handleEdit(selectedData) {
      if (!this.validateForm()) {
        return;
      }

      const params = {
        kodeparameter: selectedData,
        limit_hour: parseInt(this.limit_hour.replace(/,/g, ''))
      }

      this.$http.post('/api/parameter/edit', params).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              variant: 'danger',
              text: res.data.message ?? 'Gagal merubah limit hour, silahkan hubungi admin',
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Berhasil merubah limit hour',
            },
          });
          this.isShowModalEdit = false
          this.limit_hour = ''
          this.getListData()
        }
      }).catch(e => {
        this.loading = false
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal merubah limit hour, silahkan hubungi admin',
          },
        });
        this.loading = false
      })
    },

    validateForm() {
      if (this.limit_hour === '' || this.limit_hour === null) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi limit hour',
          },
        });
        return false;
      }

      return true
    },

    async handleRemove(item) {
      const isConfirmed = await Swal.fire({
        title: 'Konfirmasi',
        text: `Apakah Anda yakin ingin menghapus ${item.limit_hour}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, Hapus!',
        cancelButtonText: 'Batal',
      });

      if (!isConfirmed.value) {
        return;
      }

      const data = {
        kodeparameter: item.kodeparameter
      }

      this.$http.post('/api/parameter/delete', data).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'SlashIcon',
              variant: 'danger',
              text: res.data.message,
            },
          });
          return;
        }

        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'CheckIcon',
            variant: 'success',
            text: '👋Berhasil menghapus data',
          },
        });

        this.getListData()

      }).catch(e => {
        // Menampilkan pesan kesalahan lebih spesifik
        const errorMessage = e.response ? e.response.data.message : 'Gagal menghapus data';

        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: errorMessage,
          },
        });
      });
    },
  }
}
</script>
